<template>
  <div class="login">
    <div class="head">
      <div class="login-title">文旅局</div>
      <div class="login-subtitle">高效管理每个旅游从业者</div>
    </div>
    <!--登陆-->
    <div class="form">
      <div class="item">
        <input
          type="text"
          name="name"
          maxlength="11"
          placeholder="请输入您的帐号"
          autocomplete="off"
          class="name"
          v-model="user.mobile"
        />
      </div>
      <div class="item" v-if="type == 1">
        <input
          type="text"
          name="vcode"
          maxlength="6"
          placeholder="请输入验证码"
          autocomplete="off"
          class="vcode"
          v-model="user.code"
        />
        <span id="vcode" v-if="!disabled" @click="get_sms_codes"
          :class="vcodeDisabled? 'disabled-bg': ''">{{
          btnTitle
        }}</span>
        <span id="vcode" class="disabled-bg" v-if="disabled">{{ btnTitle }}</span>
      </div>
      <div class="item" v-if="type == 2">
        <input
          type="password"
          name="password"
          placeholder="请输入登录密码"
          autocomplete="off"
          class="password"
          v-model="user.password"
        />
      </div>
      <div class="submit" v-if="type==2"><button :disabled="user.mobile==''||user.password==''" @click="do_sign_in" v-html="login_text"></button></div>
      <div class="submit" v-if="type==1"><button :disabled="user.mobile==''||user.code==''" @click="do_sign_in" v-html="login_text"></button></div>
      <div class="login-method" v-if="type == 2" @click="switchType(1)">
        验证码登录
      </div>
      <div class="login-method" v-if="type == 1" @click="switchType(2)">
        账号密码登录
      </div>
    </div>
    <div class="footer">
      <!-- <div class="question" @click="onQuestionClick">没有账号？申请开通</div> -->
      <p>
        登录使用就表示同意用户协议<a
          href="javascript:;"
          @click="onAgreementClick"
          >条款和隐私</a>政策
      </p>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import cookies from "../../utils/cookies";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      mobile: "", //手机号
      verifyCode: "", //验证码
      password: "",
      btnTitle: "获取验证码",
      disabled: false, //是否可点击
      errors: {}, //验证提示信息
      user: {
        mobile: cookies.get('user')?JSON.parse(cookies.get('user')).mobile:'',
        password: '',
        code: "",
      },
      type: 2,
      login_text:"登&nbsp;录"
    };
  },
  computed: {
    vcodeDisabled() {
      return !/^1[345678]\d{9}$/.test(this.user.mobile)
    }
  },
  methods: {
    onAgreementClick() {
      this.$router.push("/agreement");
    },
    onPrivacyClick() {
      this.$router.push("/privacy");
    },
    onQuestionClick() {
      this.$router.push("/register");
    },
    switchType(type) {
      this.type = type
      this.user.password = ''
      this.user.code = ''
    },
    async do_sign_in() {
      if (this.validatemobile()) {
        const _self = this;
        if (this.type == 2) {
           if(_self.user.password==''){
            Toast('请输入登录密码')
            return false
          }
          var user = {
            mobile: _self.user.mobile,
            password: _self.user.password,
          };
        }
        if (this.type == 1) {
          var user = {
            mobile: _self.user.mobile,
            code: _self.user.code,
          };
        }
        this.login_text = "登录中..."
        const res = await this.$apis.sing_in(user);
        this.login_text = "登&nbsp;录"
       
        // cookies.set("name", res.data.boss.name);
        // cookies.set("user", res.data.user);
        
        if (res.status == 200) {
           cookies.set("token", res.data.token);
          this.$router.push("/");
        }
      }
    },
    async get_sms_codes() {
      const _self = this;
      if (this.validatemobile()) {
        const res = await this.$apis.sms_codes(_self.user.mobile);
        if (res.status == 200) {
          this.validateBtn();
          Toast("验证码已发送到您的手机，请注意查收!");
        } else {
          // Toast("发送失败!");
        }
      }
    },
    validateBtn() {
      // this.get_sms_code()
      //倒计时
      this.show = false;
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    validatemobile() {
      // return true;
      //判断输入的手机号是否合法
      if (!this.user.mobile) {
        Toast("请输入手机号");
        return false;
      } else if (!/^1[345678]\d{9}$/.test(this.user.mobile)) {
        Toast("请输入正确的手机号!");
        return false;
      } else {
        this.errors = {};
        return true;
      }
    },
  },
};
</script>
<style scoped>
@import "./style.css";
</style>